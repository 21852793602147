import { render, staticRenderFns } from "./AppDropdownMenu.vue?vue&type=template&id=01cc18e6&scoped=true"
import script from "./AppDropdownMenu.vue?vue&type=script&lang=ts"
export * from "./AppDropdownMenu.vue?vue&type=script&lang=ts"
import style0 from "./AppDropdownMenu.vue?vue&type=style&index=0&id=01cc18e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01cc18e6",
  null
  
)

export default component.exports