
import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import GrantApp from "@/sg_copy/model/GrantApp";
import {namespace} from "vuex-class";
import handleApiError from "@/shared/apiErrorUtil";
import {AppDropdownAction} from "@/sg_copy/model/AppDropdownAction";

const mySubmissionStore = namespace("mySubmissionStore");

@Component
export default class AppDropdownMenu extends Vue {
  @Prop()
  private app!: GrantApp;

  @mySubmissionStore.Action
  public deleteApp;

  @mySubmissionStore.Action
  public archiveApp;

  @mySubmissionStore.Action
  private removeOrg;

  hasUpperElements(): boolean {
    return this.archiveAllowed || this.unarchiveAllowed || this.deleteAllowed;
  }

  hasLowerElements(): boolean {
    return (
      this.shareUserAllowed ||
      this.changeUserAllowed ||
      this.assignOrgAllowed ||
      this.canRemoveOrg
    );
  }

  shouldDisableDropDown(): boolean {
    return (
      this.app.processing ||
      !(this.hasUpperElements() || this.hasLowerElements())
    );
  }

  showDivider(): boolean {
    return this.hasUpperElements() && this.hasLowerElements();
  }

  public archiveApplication(): void {
    if (this.app.userPermission.archiveAllowed) {
      this.app.processing = true;

      const data = {
        appId: this.app.id,
        archive: true,
      };

      this.archiveApp(data)
        .then(() => this.app.remove())
        .catch(error => {
          console.log("Error: " + error);
          handleApiError(
            error,
            this,
            "Error archiving application " + this.app.header
          );
        })
        .finally(() => (this.app.processing = false));
    }
  }

  public unarchiveApplication(): void {
    if (this.app.userPermission.archiveAllowed) {
      this.app.processing = true;
      const data = {
        appId: this.app.id,
        archive: false,
      };
      this.archiveApp(data)
        .then(() => this.app.remove())
        .catch(error => {
          console.log("Error: " + error);
          handleApiError(
            error,
            this,
            "Error reactivating application " + this.app.header
          );
        })
        .finally(() => (this.app.processing = false));
    }
  }

  public get deleteAllowed(): boolean {
    return this.app.userPermission && this.app.userPermission.deleteAllowed;
  }

  public get archiveAllowed(): boolean {
    return !this.app.archived && this.app.userPermission?.archiveAllowed;
  }

  public get unarchiveAllowed(): boolean {
    return this.app.archived && this.app.userPermission?.archiveAllowed;
  }

  public get shareUserAllowed(): boolean {
    return !this.app.archived && this.app.userPermission?.shareUserAllowed;
  }

  public get changeUserAllowed(): boolean {
    return !this.app.archived && this.app.userPermission?.changeUserAllowed;
  }

  public get assignOrgAllowed(): boolean {
    return !this.app.archived && this.app.userPermission?.assignToOrgAllowed;
  }

  public get canRemoveOrg(): boolean {
    return this.app.userPermission?.removeOrgAllowed;
  }

  public deleteApplication(): void {
    if (this.app.userPermission.deleteAllowed) {
      const h = this.$createElement;
      const messageVNode = h("div", [
        h(
          "strong",
          "Are you sure you want to delete Application No. " +
            this.app.header +
            "?"
        ),
        h("br"),
        h(
          "em",
          "The application will be permanently deleted. You can't undo this action."
        ),
      ]);

      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Delete Submission",
          okTitle: "Delete",
          okVariant: "danger",
        })
        .then(response => {
          if (response) {
            this.callDeleteApplication();
          }
        });
    }
  }

  public callDeleteApplication(): void {
    this.app.processing = true;
    this.deleteApp(this.app.id)
      .then(() => {
        this.app.remove();

        const message = "Application " + this.app.header + " has been deleted";
        this.$bvToast.toast(message, {
          title: "Success",
          variant: "success",
          solid: true,
        });
      })
      .catch(error => {
        handleApiError(
          error,
          this,
          "Error deleting application " + this.app.header
        );
      })
      .finally(() => (this.app.processing = false));
  }

  public share(): void {
    this.emitAction(this.app, "share");
  }

  @Emit("action")
  public emitAction(app: GrantApp, action: AppDropdownAction): void {
    // emits
  }

  public changeUser(): void {
    this.emitAction(this.app, "change");
  }

  public assignOrg(): void {
    this.emitAction(this.app, "assignOrg");
  }
}
