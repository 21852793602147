
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import GrantApp from "@/sg_copy/model/GrantApp";
import {
  ApplicantOrgMemberDto,
  GrantAppDetailDto,
  SfPermission,
  ShareApplicationDto,
  SimpleIdDto,
  UserDto,
} from "@/sg_copy/swagger-generated";
import Multiselect from "vue-multiselect";
import moment from "moment";
import ErrorMessageCard from "@/sg_copy/components/ErrorMessageCard.vue";
import {namespace} from "vuex-class";
import handleApiError from "@/shared/apiErrorUtil";
import {permissionService} from "@/sg_copy/service/PermissionService";

const MAX_USER = 100;
const mySubmissionStore = namespace("mySubmissionStore");

@Component({
  components: {
    ErrorMessageCard,
    Multiselect,
  },
})
export default class ShareApplicationModal extends Vue {
  @Prop() private app!: GrantApp;
  private appDetail: GrantAppDetailDto = null;
  private allUsers: Array<ApplicantOrgMemberDto> = null;
  private allUserMap: Map<number, ApplicantOrgMemberDto> = new Map<
    number,
    ApplicantOrgMemberDto
  >();
  private selectedUsers: Array<UserDto> = [];
  private selectedUserMap: Map<number, UserDto> = new Map<number, UserDto>();
  private error = false;
  private usernameInput: Array<any> = [];
  private usernameInputOptions: Array<any> = [];
  private loading = true;
  private validationUser: string = null;
  private validationExpiry: string = null;
  private expiryDate = "";
  private shareNote = "";
  private existing = false;
  private readonly ALL_PERMISSIONS = [
    SfPermission.GRANT_APP_CREATE,
    SfPermission.GRANT_APP_WRITE,
    SfPermission.GRANT_APP_READ,
    SfPermission.GRANT_APP_SUBMIT,
  ];
  private selectedPermissions: Array<SfPermission> = [];

  @mySubmissionStore.Action
  public getApp;

  @mySubmissionStore.Action
  public getMembers;

  @mySubmissionStore.Action
  public shareApp;

  created() {
    if (this.app) {
      this.load();
    }
  }

  removeUser(tag) {
    this.selectedUsers = this.selectedUsers.filter(e => {
      return e.id !== tag.id;
    });
    this.selectedUserMap.delete(tag.id);
  }

  @Watch("selectedUsers")
  onUsernameInputChange(val) {
    if (this.selectedUsers.length > 0 && this.selectedUsers.length < MAX_USER) {
      this.validationUser = null;
    }
  }

  @Watch("expiryDate")
  onValidationExpiryChange(val) {
    if (val && val.length > 0) {
      this.validationExpiry = null;
    }
  }

  @Watch("selectedPermissions")
  onSelectedChange() {
    this.usernameInput = [];
    this.updateOptionFromList();
  }

  public addUsers() {
    if (!this.usernameInput) {
      return;
    }

    if (
      this.usernameInput.length > 0 &&
      this.selectedUsers.length >= MAX_USER
    ) {
      this.validationUser = "Maximum users reached";
    }

    for (const username of this.usernameInput) {
      let user = this.allUserMap.get(Number.parseInt(username.id));
      if (
        user &&
        !this.selectedUserMap.get(user.user.id) &&
        this.selectedUsers.length < MAX_USER
      ) {
        this.selectedUsers.push(user.user);
        this.selectedUserMap.set(user.user.id, user.user);
        this.validationUser = null;
      }
    }
    this.usernameInput = [];
  }

  public load() {
    this.loading = true;
    this.usernameInput = [];
    this.selectedUsers = [];
    this.selectedUserMap.clear();
    this.allUserMap.clear();
    this.error = false;
    this.appDetail = null;
    this.validationUser = null;
    this.validationExpiry = null;
    this.expiryDate = null;
    this.allUserMap.clear();
    this.shareNote = "";
    this.existing = false;
    this.selectedPermissions = this.ALL_PERMISSIONS.slice(0);

    const promiseApp = this.getApp(this.app.id)
      .then(response => {
        this.appDetail = response.data;
        if (!this.appDetail) {
          this.error = true;
        } else {
          if (this.appDetail.shareExpiry) {
            this.expiryDate = moment(this.appDetail.shareExpiry).format(
              "YYYY-MM-DD"
            );
            this.existing = true;
          }
          this.selectedUserMap.clear();
          this.selectedUsers = [];
          if (this.appDetail.shares) {
            for (const share of this.appDetail.shares) {
              this.selectedUsers.push(share);
              this.selectedUserMap.set(share.id, share);
              this.existing = true;
            }
          }
          this.shareNote = this.appDetail.shareNote
            ? this.appDetail.shareNote
            : "";
        }
      })
      .catch(() => (this.error = true));

    const data = {
      appId: this.app.id,
      operation: "share",
    };
    const promiseUser = this.getMembers(data)
      .then(response => {
        this.allUsers = response.data.data;
        for (const user of this.allUsers) {
          this.allUserMap.set(user.user.id, user);
        }
      })
      .catch(() => (this.error = true));

    Promise.all([promiseApp, promiseUser])
      .then(() => {
        if (!this.appDetail || !this.allUsers) {
          this.error = true;
          this.$bvModal.hide("share-application-modal");
          handleApiError(
            "Unable to load application detail",
            this,
            "Unable to load application detail"
          );
        } else {
          this.loading = false;
        }
      })
      .then(() => {
        if (!this.loading) {
          this.usernameInput = null;
          this.updateOptionFromList();
        }
      });
  }

  public updateOptionFromList() {
    if (!this.allUsers) {
      return;
    }

    let permissions = this.selectedPermissions;
    if (permissions.length === 0) {
      permissions = this.ALL_PERMISSIONS;
    }

    this.usernameInputOptions = [];
    for (const member of this.allUsers) {
      if (permissionService.anyMatch(member.permissions, permissions)) {
        this.usernameInputOptions.push({
          text: member.user.name,
          id: member.user.id.toString(),
        });
      }
    }
  }

  public shareUser(bvModalEvt) {
    this.addUsers();

    this.validationUser = null;
    this.validationExpiry = null;

    bvModalEvt.preventDefault();
    this.app.processing = true;

    if (!this.existing && this.selectedUsers.length === 0) {
      this.validationUser = "Please select at least one user.";
    }

    if (this.expiryDate) {
      if (!moment(this.expiryDate, "YYYY-MM-DD", true).isValid()) {
        this.validationExpiry = "Invalid date.";
      }

      if (
        !moment(this.expiryDate, "YYYY-MM-DD", true).isAfter(
          moment().format("YYYY-MM-DD")
        )
      ) {
        this.validationExpiry = "Please enter a date in the future.";
      }

      if (
        moment(this.expiryDate, "YYYY-MM-DD", true).isAfter(
          moment().add(25, "year")
        )
      ) {
        this.validationExpiry = "Maximum shared expiry is 25 years.";
      }
    }

    if (this.validationExpiry || this.validationUser) {
      this.app.processing = false;
    } else {
      this.callShareAppApi();
    }
  }

  public callShareAppApi() {
    const users: Array<SimpleIdDto> = [];
    for (const user of this.selectedUsers) {
      users.push({id: user.id});
    }

    const shareDto: ShareApplicationDto = {
      expiryDate: this.expiryDate,
      shareNote: this.shareNote,
      users: users,
    };

    const data = {
      appId: this.app.id,
      shareDto: shareDto,
    };

    this.shareApp(data)
      .then(() => {
        this.app.processing = false;
        this.emitUserShared();
        this.$bvModal.hide("share-application-modal");

        const message =
          "Application " +
          this.app.header +
          (this.selectedUsers.length === 0
            ? " is no longer shared with other user/s."
            : " is now shared with " +
              this.selectedUsers.length +
              " users " +
              (this.expiryDate
                ? " until " + moment(this.expiryDate).format("DD MMM YYYY")
                : "without expiry date") +
              ".");
        this.$bvToast.toast(message, {
          title: "Success",
          autoHideDelay: 5000,
          variant: "success",
          solid: true,
        });
      })
      .catch(error => {
        this.app.processing = false;
        this.error = true;
        handleApiError(
          error,
          this,
          "Error sharing application " + this.app.header
        );
      });
  }

  @Emit("user-shared")
  public emitUserShared() {
    //emits
  }

  get permissionOptions() {
    return this.ALL_PERMISSIONS.map(permission => ({
      value: permission,
      text: permissionService.getDescriptionFor(permission),
    }));
  }
}
